import { NavigationRoutes } from 'common/routes';
import { BottomNavigationBar } from 'components/widgets/footer';
import ResponsiveAppActionComponent from 'components/widgets/layout/actions';
import { AppLayoutActionsContext } from 'components/widgets/layout/actions/context';
import { AppActionType } from 'components/widgets/layout/actions/types';
import { useIsSmallScreen } from 'hooks/use-is-small-screen';
import React from 'react';

interface Props {
  tab: NavigationRoutes;
}

export default function KurosimBottomNavigationBar(props: Props) {
  const { tab } = props;

  const navigations = React.useContext(AppLayoutActionsContext).filter(
    (action) => action.type === AppActionType.Navigation,
  );

  const isMobile = useIsSmallScreen();

  if (!isMobile) return null;

  return (
    <BottomNavigationBar<NavigationRoutes> value={tab}>
      {navigations.map((action) => (
        <ResponsiveAppActionComponent {...action} key={action.key} />
      ))}
    </BottomNavigationBar>
  );
}
