/**
 * Turns a try catch block into an expression. This is useful if you want to run a function that can fail, but provide a fallback
 * ```
 * const {
 *   data = {
 *     a: 'dummy-data'
 *   }
 * } = tryFn(() => JSON.parse(localStorage.get('unavailable-key')));
 * ```
 */
export function tryFn<T>(fn: () => T): {
  data: T | undefined;
  error: any;
} {
  try {
    return { data: fn(), error: undefined };
  } catch (e) {
    console.error(e);
    return { data: undefined, error: e };
  }
}
