import { AssetPaths } from 'common/constants/assets';

const onboardingAssets = [
  AssetPaths.OnboardingFirst,
  AssetPaths.OnboardingSecond,
  AssetPaths.OnboardingThird,
  AssetPaths.OnboardingFourth,
];

export default onboardingAssets;

export const onBoardingBackground = [
  AssetPaths.OnboardingBackgroundFirst,
  AssetPaths.OnboardingBackgroundSecond,
];
