import { Carousel, Embla } from '@mantine/carousel';
import onboardingAssets, { onBoardingBackground } from 'assets/onboarding';
import classNames from 'classnames';
import { LocalStorageKeys } from 'common/constants/browser-storage-keys';
import { NavigationRoutes } from 'common/routes';
import Button from 'components/elements/button';
import Text from 'components/elements/text';
import { AppFooter } from 'components/widgets/footer';
import BackgroundImage from 'components/widgets/image/background';
import { AppLayout } from 'components/widgets/layout';
import useKurosimNavigation, {
  StackNavigation,
} from 'hooks/use-kurosim-navigation';
import { createHistoryEntry } from 'hooks/use-kurosim-navigation/utils';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import structuralStyles from 'styles/layout.css';

import { OnboardingCarouselStyle } from './styles.css';

export function useOnboardingOnFirstVisit() {
  const { replace } = useKurosimNavigation();
  React.useEffect(() => {
    if (!localStorage.getItem(LocalStorageKeys.HasShownOnboarding)) {
      replace(NavigationRoutes.Guides, { query: undefined });
    }
  }, [replace]);
}

export default function Onboarding() {
  const { t } = useTranslation();
  const { replace, refresh } = useKurosimNavigation();
  const [embla, setEmbla] = React.useState<Embla | null>(null);

  const query = useRouter()?.query?.q?.toString().toLowerCase();
  const isGuide = query === 'guide';

  const slides = [
    {
      content: (
        <Text ta="center" mt={24} maw="calc(100% - 32px)">
          <Text span textVariant="body1Semibold">
            {t('profile:welcome_to_our_kurosim')}&nbsp;
          </Text>
          {t('profile:onboarding_01')}
        </Text>
      ),
    },
    {
      content: (
        <Text ta="center" mt={24} maw="calc(100% - 32px)">
          {t('profile:onboarding_02')}
        </Text>
      ),
    },
    {
      content: (
        <Text ta="center" mt={24} maw="calc(100% - 32px)">
          {t('profile:onboarding_03')}
        </Text>
      ),
    },
    {
      content: (
        <Text ta="center" mt={24} maw="calc(100% - 32px)">
          {t('profile:onboarding_04')}
        </Text>
      ),
    },
  ];

  React.useEffect(() => {
    if (!embla) return;
    if (typeof window !== 'undefined') {
      localStorage.setItem(LocalStorageKeys.HasShownOnboarding, 'true');
    }
    const intervalId = setInterval(() => {
      embla.scrollNext();
    }, 4000);
    return () => clearInterval(intervalId as any);
  }, [embla]);

  return (
    <AppLayout
      noSideNavigation
      Footer={
        <AppFooter
          customization={{ root: { border: !!isGuide } }}
          mainButtonProps={{
            type: 'main',
            fullWidth: true,
            onClick: () => {
              StackNavigation.popWhile(
                (entry) => entry.pathname === NavigationRoutes.Guides,
                createHistoryEntry(NavigationRoutes.Store),
              );
              refresh();
            },
            variant: { variant: isGuide ? 'primary' : 'secondary' },
            children: isGuide ? t('common:done') : t('common:explore'),
          }}
          extendedComponent={
            isGuide ? undefined : (
              <Button fullWidth onClick={() => replace(NavigationRoutes.Login)}>
                {t('common:login')}
              </Button>
            )
          }
        />
      }
    >
      <div
        className={classNames(
          structuralStyles.fill({ height: true }),
          structuralStyles.flexbox({ direction: 'column', fill: true }),
        )}
      >
        <Carousel
          height="100%"
          withIndicators
          withControls={false}
          getEmblaApi={setEmbla}
          classNames={OnboardingCarouselStyle}
          style={{ flex: 1 }}
        >
          {slides.map((slide, idx) => (
            <Carousel.Slide
              key={idx}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                className={structuralStyles.flexbox({
                  direction: 'column',
                  fill: true,
                })}
              >
                <BackgroundImage
                  zIndex={-1}
                  width={768}
                  fill={false}
                  height={650}
                  imageFit="cover"
                  src={onBoardingBackground[idx % 2]}
                  alt={`onboarding-background-${idx}`}
                />
                <Image
                  width={300}
                  height={300}
                  src={onboardingAssets[idx]}
                  alt={`onboarding-kurosim-slide-${idx}`}
                />
                {slide.content}
              </div>
            </Carousel.Slide>
          ))}
        </Carousel>
      </div>
    </AppLayout>
  );
}
