import { useBrowseProducts } from 'api-hooks/product';
import { NavigationRoutes } from 'common/routes';
import { UseQueryWrapperComponent } from 'components/common/fetch-wrapper-component';
import useKurosimNavigation, {
  StackNavigation,
} from 'hooks/use-kurosim-navigation';
import { useOnboardingOnFirstVisit } from 'modules/onboarding';
import React from 'react';

import BrowseScreenBody from './body';

export default function BrowseScreen() {
  const query = useBrowseProducts();
  const { prefetch } = useKurosimNavigation();
  useOnboardingOnFirstVisit();

  //for onboarding
  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    //reset
    StackNavigation.resetTo(NavigationRoutes.Store);
  }, [prefetch]);

  return (
    <UseQueryWrapperComponent query={query}>
      {(props) => {
        return <BrowseScreenBody {...props} />;
      }}
    </UseQueryWrapperComponent>
  );
}
