import {
  DiceFive,
  DiceFour,
  DiceOne,
  DiceSix,
  DiceThree,
  DiceTwo,
} from '@phosphor-icons/react';
import { useGetCart } from 'api-hooks/cart';
import {
  ShoppingCartIcon,
  MoreIcon as MoreHorizontalIcon,
} from 'common/assets';
import { NavigationRoutes } from 'common/routes';
import colors from 'common/styles/colors';
import BottomSheet, {
  BottomSheetRemote,
} from 'components/widgets/bottom-sheet';
import { AppHeader } from 'components/widgets/header';
import useKurosimNavigation from 'hooks/use-kurosim-navigation';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

export function ShoppingCartActionIcon(
  props: React.ComponentProps<typeof AppHeader.Action>,
) {
  const { push } = useKurosimNavigation();
  const { t } = useTranslation();
  const { data } = useGetCart();

  return (
    <AppHeader.Action
      indicator={data?.data?.items.length}
      onClick={() => {
        push(NavigationRoutes.Cart);
      }}
      tooltip={t('common:my_cart')}
      {...props}
    >
      {(props) => <ShoppingCartIcon {...props} />}
    </AppHeader.Action>
  );
}

interface RandomizeActionIconProps {
  onClick?(): void;
}

export function RandomizeActionIcon(props: RandomizeActionIconProps) {
  const icons = [DiceOne, DiceTwo, DiceThree, DiceFour, DiceFive, DiceSix];
  const [number, setNumber] = React.useState(
    Math.floor(Math.random() * icons.length),
  );
  const Icon = icons[number];
  return (
    <AppHeader.Action
      onClick={() => {
        setNumber(Math.floor(Math.random() * icons.length));
        props?.onClick?.();
      }}
    >
      {(props) => {
        return <Icon {...props} color={colors.foregroundTertiary} />;
      }}
    </AppHeader.Action>
  );
}

interface MoreIcons {
  more: React.ReactNode;
  bottomSheetProps?: Omit<
    React.ComponentProps<typeof BottomSheet>,
    'children' | 'ref'
  >;
}

export function MoreIcon(props: MoreIcons) {
  const remote = React.useRef<BottomSheetRemote | null>(null);
  const { t } = useTranslation();
  return (
    <>
      <AppHeader.Action
        onClick={() => {
          remote.current?.open();
        }}
      >
        {(props) => {
          return (
            <MoreHorizontalIcon {...props} color={colors.foregroundTertiary} />
          );
        }}
      </AppHeader.Action>
      <BottomSheet
        ref={remote}
        {...props.bottomSheetProps}
        title={t('profile:more')}
      >
        {props.more}
      </BottomSheet>
    </>
  );
}
