import { NavigationRoutes } from 'common/routes';
import { AppLayout } from 'components/widgets/layout';
import BrowseScreen from 'modules/main/browse';
import KurosimHeader from 'modules/main/header';
import KurosimBottomNavigationBar from 'modules/main/navigation';
import React from 'react';

import { NextPageWithLayout } from './_app';

(BrowseScreen as NextPageWithLayout).getLayout = (page) => {
  return (
    <AppLayout
      Header={<KurosimHeader tab={NavigationRoutes.Store} />}
      Footer={<KurosimBottomNavigationBar tab={NavigationRoutes.Store} />}
    >
      {page}
    </AppLayout>
  );
};

export default BrowseScreen;
